.upload-adv-files-modal {
  min-width: 40rem;
  max-width: 80vw;

  &__description {
    @include flex(column, flex-start, flex-start);
    @include margin(bottom, spacing-03);

    & h4 {
      @include margin(bottom);
    }

    & span {
      @extend .subtext;
      color: get-colour('secondary-text');
    }
  }

  &__results-list-container {
    @include flex(column, center, flex-start);
    @include margin(bottom);
  }

  &__results-title {
    position: sticky;
    background-color: get-colour('grey-lighter-90');
    z-index: get-zindex('upload-adv-results-title');
    top: 0;
    @include padding(vertical);
    @include margin(bottom, spacing-02);

    & > * {
      @include padding(horizontal, spacing-03);
    }
  }

  &__results-list {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 400px;
    width: 100%;
  }

  &__footer {
    width: 100%;
    @include margin(vertical, spacing-04);
    @include flex(row, center, flex-end);

    & > * {
      @include margin(right);
    }
  }

  &__verifying-files {
    @extend .subtext;
    width: 100%;
    color: get-colour('warning');
    box-shadow: $drop-shadow-md;
    @include flex(row, center, flex-start);
    @include margin(bottom, spacing-11);
    @include padding(all);

    & > * {
      @include margin(right, spacing-02);
    }
  }


  &__verify-files-subtext {
    @extend .p3;
    color: get-colour('secondary-text');
  }
}