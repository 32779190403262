.cookies-banner {
  background: rgba( 255, 255, 255, 0.4 );
  width: auto;
  height: auto;
  z-index: get-zindex('cookies-banner');
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%) ;
  box-shadow: $box-shadow-lg;
  backdrop-filter: blur( 7px );
  -webkit-backdrop-filter: blur( 7px );
  border-radius: 6px;
  border: 1px solid get-colour('grey-lighter-90');
  @include padding(horizontal, spacing-02);
  @include padding(vertical, spacing-04);
  @include flex(row, flex-end, flex-start);

  &__content {
    @include flex(column, flex-start, flex-start);
    @include margin(right, spacing-02);
  }
}