// TODO: add media queries for very low resolution screens

$sb-transition-duration: 0.4s;
$sb-collapse-transition: $sb-transition-duration ease-in-out;

.sidebar {
  position: relative;
  min-width: 50px;
  max-width: 50px;
  height: 100vh;
  background-color: get-colour('primary');
  transition: $sb-collapse-transition;
  @include flex(column, unset, unset);

  &--collapsed {
    @extend .sidebar;
    min-width: 50px;
    max-width: 50px;
    transition: $sb-collapse-transition;
  }

  @include for-screen-size(phone, up) {
    min-width: $sidebar-width-phone;
    max-width: $sidebar-width-phone;
    transition: $sb-collapse-transition;
  }

  @include for-screen-size(tablet, up) {
    max-width: $sidebar-width-tablet;
    min-width: $sidebar-width-tablet;

    &--collapsed {
      min-width: 50px;
      max-width: 50px;
      transition: $sb-collapse-transition;
    }
  }

  @include for-screen-size(desktop, up) {
    max-width: $sidebar-width-desktop;
    min-width: $sidebar-width-desktop;

    &--collapsed {
      min-width: 64px;
      max-width: 64px;
      transition: $sb-collapse-transition;
    }
  }
}

.sidebar__logo {
  @include flex(row);
  height: 80px;
  filter: invert(100%)
  saturate(0)
  grayscale(100%)
  contrast(200%)
  brightness(100);
  transition: opacity 1s ease-in-out;

  &-small {
    @extend .sidebar__logo;
    width: 100%;
    margin: 0 auto;

    & img {
      width: 40px;
      height: 40px;
    }
  }

  &--collapsed {
    @extend .sidebar__logo;
    width: 100%;
    margin: 0 auto;

    & img {
      width: 40px;
      height: 40px;
    }
  }
}

.sidebar__img {
  width: 112.8px;
  height: 24px;
  @include margin(vertical, spacing-06);

  @include for-screen-size(desktop, up) {
    width: 150.4px;
    height: 32px;
  }
}

.sidebar__divider {
  border: 1px solid get-colour('green-lighter-10');
  width: 100%;
  opacity: 0.8;
  @include margin(bottom, spacing-06);
}

.sidebar__collapse-button {
  position: absolute;
  right: -8px;
  top: 104px;
  height: 24px;
  width: 24px;
  opacity: 0;
  background-color: get-colour('green-lighter-10');
  border: 1px solid get-colour('green-darker-10');
  border-radius: 25px;
  cursor: pointer;
  z-index: get-zindex('sidebar-collapsible-button');
  @include flex();

  @include for-screen-size(tablet, up) {
    opacity: 1;
  }

  &:hover {
    background-color: get-colour('green-darker-20');
    transition: 0.1s ease-out;
  }

  & svg {
    transform: rotate(180deg);
    transition: $sb-collapse-transition;
    fill: get-colour('white');
    width: 1.5rem;
    height: 1.5rem;
  }

  &--collapsed {
    @extend .sidebar__collapse-button;

    & svg {
      transform: rotate(0);
      transition: $sb-collapse-transition;
    }
  }
}

.sidebar__menu {
  // flex-grow value guarantees that the menu
  // will dynamically take all available vertical
  // spacing between the logo and the footer.
  overflow-y: auto;
  position: relative;
  height: 100%;
  scrollbar-width: none; // hide scrollbar in firefox
  &::-webkit-scrollbar {
    //hide scrollbar in chrome
    display: none;
  }
}

.sidebar__menu-section {
  width: 100%;
  @include flex(row, center, flex-start);
  @include margin(bottom);

  &-container {
    @include margin(bottom, spacing-05);
  }

  &-title {
    @extend .overline;
    @extend .sidebar__menu-label;
    @include margin(right);

    &--collapsed {
      display: none;
      margin: 0;
    }
  }
}

.sidebar__footer {
  height: auto;
  width: 100%;
  position: absolute;
  bottom: 0;
  @include margin(bottom, spacing-04);
}

.sidebar__menu-label {
  height: 24px;
  color: get-colour('white');
  opacity: 0;
  transition: opacity 1.2s ease-in-out 0.2s;

  @include flex(row, center, flex-start);
  @include padding(left, spacing-02);
  @include margin(bottom, spacing-02);

  @include for-screen-size(tablet, up) {
    opacity: 1;
  }

  &--collapsed {
    @extend .sidebar__menu-label;
    transition: 0s;
    opacity: 0;
  }
}


.sidebar__item {
  $sidebar-item-height: 40px;
  height: $sidebar-item-height;
  width: 100%;
  text-decoration: none;
  color: get-colour('grey-lighter-80');
  @include flex(row, center, flex-start);
  @include padding(left, spacing-02);

  &:hover {
    background-color: get-colour('green-darker-10');
    color: get-colour('white');
  }

  &--active {
    @extend .sidebar__item;
    box-sizing: inherit;
    background-color: get-colour('green-lighter-10');
    border-left: 5px solid get-colour('green-lighter-20');
    height: $sidebar-item-height;
    transition: 0.2s ease-out;
    box-shadow: $drop-shadow-xxl;
    color: get-colour('white');
    @include padding(left, spacing-01);

    &:hover {
      background-color: get-colour('green-lighter-10');
    }
  }
}

.sidebar__label {
  @extend .subtext;
  font-weight: 600;
  opacity: 0;
  transition: opacity 1.2s ease-in-out 0.2s;

  &--collapsed {
    opacity: 0;
  }

  @include for-screen-size(tablet, up) {
    opacity: 1;
  }
}

.sidebar__icon {
  height: 1.5rem;
  width: 1.5rem;
  font-size: 0.8rem;
  @include flex();
  @include margin(right, spacing-01);

  & svg,
  svg path {
    height: 1.3rem;
    width: 1.3rem;
  }

  @include for-screen-size(tablet, up) {
    font-size: 0.9rem;
    @include margin(right, spacing-02);
  }

  @include for-screen-size(desktop, up) {
    font-size: 1rem;
  }
}

.sidebar__scroll-arrow {
  @extend .w-100;
  height: 32px;
  @include flex();
  cursor: pointer;

  &:hover {
    background-color: get-colour('green-darker-30');
    transition: 0.3s ease-in-out;
  }

  &--hidden {
    display: none;
  }

  &--up {
    @extend .sidebar__scroll-arrow;
    box-shadow: $drop-shadow-xxl;
    @include margin(bottom, spacing-01);
  }

  &--down {
    @extend .sidebar__scroll-arrow;
    box-shadow: $drop-shadow-xxl-top;
    @include margin(top, spacing-01);
  }

  & svg {
    fill: get-colour('grey-lighter-60');
  }
}
