.table {
  height: 100%;
  background-color: get-colour('white');
  @extend .w-100;
}
// 52px == App inner container padding
// 20px == Bottom padding
$table-max-height: calc(100vh - calc(52px + $topbar-height + 20px));
.table__container {
  @extend .w-100;
  max-height: $table-max-height;
  background-color: get-colour('grey-lighter-95');
  position: relative;
  border: 1px solid get-colour('grey-lighter-60');
  overflow-x: scroll;
  overflow-y: hidden;
  @include padding(horizontal, spacing-03);

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    background-color: get-colour('grey-lighter-95');

    &-thumb {
      background-color: get-colour('grey-darker-20');
      border-radius: 3px;
    }
  }
}

.table__inner-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: auto;
  // 50px == footer max-height
  // 110px == header max-height
  // 10px == horizontal scroll height and margins
  // 38px == header and footer padding
  max-height: calc($table-max-height - calc(50px + 110px + 10px + 38px));
}

.table__header {
  max-height: 110px;
  position: sticky;
  top: -1px;
  background-color: get-colour('white');
  z-index: get-zindex('table-header');
  border-bottom: 1px solid get-colour('grey-lighter-60');
  border-top: 1px solid get-colour('grey-lighter-60');
  box-shadow: $drop-shadow-md;
}

.table__header-cell {
  height: 100%;
  @include flex(column, center, center);

  &-container {
    height: 100%;
    @include flex(column, flex-start, space-between);
  }
}

.table__top-bar {
  width: 100%;
  top: 0;
  background-color: #f7f7f7;
  z-index: get-zindex('table-top-bar');
  @include padding(vertical, spacing-02);
  @include flex(row, center, space-between);

  &-row {
    border-bottom: 1px solid get-colour('grey-lighter-80');
    @include padding(vertical);
    @include margin(bottom);
  }

  &-export-icon {
    width: 100%;
    @include flex(row, center, flex-end);
    color: get-colour('btn-secondary-default-text');
  }
}

.table__top-bar-left-wing,
.table__top-bar-right-wing {
  @include flex();
}

.table__top-bar-left-wing {
  height: 100%;
  width: 100%;
  @include flex(row, center, flex-start);
}

.table__top-bar-right-wing {
  width: 100%;
  justify-content: space-between;
}

.table__top-bar-action-buttons {
  @include flex(row, center, flex-start);

  & > * {
    @include margin(left);
  }
}

.table__top-bar-dropdown {
  width: 10rem;
}

.table__top-bar-title {
  @extend .h3;
  @include margin(right, spacing-02);
}

.table__top-bar-tabs {
  @include flex(row, center, flex-start);

  &-item {
    border-bottom: 2px solid get-colour('grey-lighter-60');
    border-radius: 4px;
    transition: border-color .2s ease-in-out;
    cursor: pointer;
    @include padding(all);
    @include margin(right);
    @include flex(column, flex-start, center);

    &--active {
      @extend .table__top-bar-tabs-item;
      border-color: get-colour('green-lighter-20');
    }
    &--disabled {
      @extend .table__top-bar-tabs-item;
      color: get-colour('grey-lighter-80');
      cursor: default;
      pointer-events: none;

      &:after {
        color: get-colour('grey-lighter-20');
      }
    }
  }
}

.table__top-bar-right-wing > * {
  @include margin(left, spacing-01);
}

.table__top-bar-selected-count {
  @extend .label;
  white-space: nowrap;
  @include flex(row, center, flex-start);
  @include margin(right);
}

.table__header-title {
  @extend .h4;
  @include flex(row, center, flex-start);
}

.table__header-action-column {
  width: 100px;
}

.table__filter {
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  color: get-colour('grey-lighter-20');
  @include padding(all, none, $important: true);
}

.table__filter-popup {
  visibility: visible;
  z-index: get-zindex('table-filter-popup');
  border: 1px solid get-colour('grey-lighter-90');

  &--hidden {
    visibility: hidden;
  }

  &-close-icon {
    fill: get-colour('error');
  }
}

.table__date-range-select {
  color: get-colour('grey-lighter-20');
  border-radius: 6px;
  background-color: get-colour('white');

  @extend .subtext;
  @include flex(row, center, space-around);
  @include margin(top);
  z-index: get-zindex('table-date-range-select');
}

.table__date-range-select-text {
  @include margin(right);
}

.table__icon {
  height: 10px;
  width: 10px;
  fill: get-colour('green-lighter-20');
  @include margin(right);
}


.table__row {
  border: 1px solid get-colour('grey-lighter-80');
  transition-duration: 0.2s;
  &--header {
    height: 100%;
    @extend .table__row;
  }

  &--body {
    @extend .table__row;
    &:nth-child(odd) {
      background-color: get-colour('grey-lighter-95');
    }

    &--selected {
      @extend .table__row--body;
      transition-duration: 0.2s;
      background-color: get-colour('green-lighter-90') !important;
    }

    &--disabled {
      @extend .table__row--body;
      pointer-events: none;
      transition-duration: 0.2s;
      color: get-colour('grey-lighter-40') !important;
      background-color: get-colour('grey-lighter-90') !important;
    }

    &--success {
      @extend .table__row--body;
      transition-duration: 0.2s;
      background-color: get-colour('lime-lighter-80') !important;
      &:hover {
        background-color: get-colour('grey-lighter-90') !important;
      }
    }

    &--warning {
      @extend .table__row--body;
      transition-duration: 0.2s;
      background-color: get-colour('orange-lighter-80') !important;
      &:hover {
        background-color: get-colour('grey-lighter-90') !important;
      }
    }

    &--error {
      @extend .table__row--body;
      transition-duration: 0.2s;
      background-color: get-colour('red-lighter-80') !important;
      &:hover {
        background-color: get-colour('grey-lighter-90') !important;
      }
    }


    &:hover {
      background-color: get-colour('grey-lighter-90');
    }
  }

  &-icons {
    height: 100%;
    @include flex();
  }

  &-icon {
    width: 34px;
    height: 28px;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    fill: get-colour('grey-lighter-20');
    @include flex();
  }
}

.table__row:hover .table__row-icon {
  opacity: 1;
}

.table__row--body:nth-child(odd) {
  background-color: get-colour('white');
}

.table__row--body:nth-child(odd):hover {
  background-color: get-colour('grey-lighter-90');
}

.table__no-results-row {
  width: 100%;
  flex-wrap: wrap;
  @include flex();
}

.table__row-actions {
  width: 100px;
  font-size: 1.7rem;
  line-height: 0.4rem;
  color: get-colour('green-lighter-20');
  cursor: pointer;
  @include flex(column);
  @include padding(top, spacing-02);
}

.table__no-results-cell {
  margin: 0 auto;
}

.table__cell {
  vertical-align: middle;
  text-align: left;
  @extend .p2;
  @include padding(horizontal);

  &--header {
    position: relative;
    @extend .table__cell;
    @include bold(bolder);
    @include padding(vertical, spacing-02);
  }

  &--body {
    @extend .table__cell;
    font-size: 1rem;
    @include padding(vertical, spacing-04);
    &.form-error {
      color: get-colour('error');
    }
    &.form-warning {
      color: get-colour('warning');
    }
  }

  &-container {
    @include flex(row, center, flex-start);
  }

  &-content {
    @extend .table__cell;
    @include padding(all, none);
    @include flex(column, flex-start, flex-start);
  }

  &-icon {
    @extend .table__cell;
    @include flex(row, center, center);
  }
}

.table__footer {
  width: 100%;
  max-height: 50px;
  position: sticky;
  bottom: 0;
  background-color: #f7f7f7;
  z-index: get-zindex('table-footer');
  @include flex(row, center, space-between);
  @include padding(top, spacing-03);

  &-results {
    width: 715px;
    color: get-colour('grey-lighter-20');
    @include flex(row, flex-end, flex-start);

    & > * {
      @include margin(right);
    }
  }
}

.table__form {
  width: auto;
  height: auto;
  z-index: get-zindex('table-form');
}

.table__form-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: get-zindex('table-form-overlay');
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition-duration: 0.3s;

  &--show {
    @extend .table__form-overlay;
    z-index: get-zindex('table-form-overlay-show');
    opacity: 1;
  }

  @extend .w-100;
  @extend .h-100;
}

.table__pagination {
  color: get-colour('grey-lighter-20');
  @extend .w-100;
  @include flex(row, center, flex-end);
}

.table__pagination > * {
  @include margin(left, spacing-03);
}

.table__pagination-dropdown {
  display: inline-block;
  width: 84px;
  @include margin(left);
}

.table__arrow {
  background-color: transparent;
  color: get-colour('green-lighter-30');

  @include bold(bolder);
  @include flex(row, center, center);

  &:disabled {
    color: get-colour('grey-lighter-20');
    cursor: default !important;
  }
}


.table__sorting-arrows {
  display: inline-flex;
  flex-direction: column;
  @include margin(left, spacing-02);
  @include margin(top);
}

.table__sorting-arrow {
  @extend .table__arrow;
  line-height: 16px;

  &--not-sorted {
    @extend .table__sorting-arrow;
    color: get-colour('grey-lighter-80');
  }
}

.table__cell-json {
  font-size: small;
}

// Overwrite react-date-range component style
.rdrDayToday .rdrDayNumber span:after {
  background: get-colour('green-lighter-30') !important;
}

.rdrStartEdge {
  background: get-colour('green-lighter-10') !important;
}

.rdrInRange {
  background: get-colour('green-lighter-10') !important;
}

.rdrEndEdge {
  background: get-colour('green-lighter-10') !important;
}

.rdrDateDisplay {
  color: get-colour('green-lighter-10') !important;
}

.rdrStaticRangeSelected {
  color: get-colour('green-lighter-10') !important;
}

.rdrDayHovered {
  color: get-colour('green-lighter-10') !important;
}

.table-filter-popup {
  @include flex(row, center, flex-start);

  &__content {
    z-index: get-zindex('table-filter-content');
    background-color: get-colour('white');
    border: 1px solid get-colour('grey-lighter-80');
    border-radius: 2px;
    box-shadow: $drop-shadow-md;
    @include padding(horizontal, spacing-01);
    @include padding(vertical, spacing-02);
    overflow: auto;
    max-height: 49vh;

    &--hidden {
      visibility: hidden;
    }
  }

  &__header {
    @include flex(row, flex-start, flex-start);
  }

  &__text {
    color: get-colour('grey-lighter-60');

    &--filtered {
      color: get-colour('grey-darker-20');
      font-weight: 600;
    }
  }

  &__button {
    @include flex(row, center, flex-start);

    & svg {
      fill: get-colour('grey-lighter-20');
    }

    & > * {
      @include margin(right);
    }
  }
}

.table-checkbox-filter {
  z-index: get-zindex('table-checkbox-filter');
  & > * {
    @include margin(bottom);
  }
}

.table-number-range-filter {
  width: 230px;
  z-index: get-zindex('table-number-range-filter');
  @include flex(row, flex-end, flex-start);


  &__separator {
    color: get-colour('grey-lighter-40');
    @include margin(bottom, spacing-02);
    @include margin(horizontal);
  }
}
