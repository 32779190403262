.idle-timer-popup__overlay {
  position: absolute;
  display: none;
  left: 0;
  top: 0;
  z-index: get-zindex('idle-timer-overlay');
  background-color: rgba(0, 0, 0, 0.4);
  @extend .min-vw-100;
  @extend .min-vh-100;

  &--visible {
    @extend .idle-timer-popup__overlay;
    @include flex();
  }
}

.idle-timer-popup__container {
  @include padding();
}

.idle-timer-popup__title {
  @extend .h4;
}

.idle-timer-popup__buttons {
  @include flex(row, center, space-between);
}
