/* Made by Jimmy Gillam */
$checked-color: get-colour('green-lighter-20');
$unchecked-color: get-colour('grey-lighter-20');
$disabled-color: get-colour('grey-lighter-40');
$checkbox-dimensions: 14px;
$background-color: get-colour('white');
$duration: 0.05s;

.checkbox__container {
  width: 100%;
  height: $checkbox-dimensions;
  position: relative;
  @include flex(row, center, flex-start);
}

.checkbox {
  @extend .cursor-pointer;
  height: calc(#{$checkbox-dimensions} + 4px);
  width: calc(#{$checkbox-dimensions} + 4px);
  display: inline-table;
  z-index: get-zindex('checkbox');

  &--indeterminate {
    @extend .checkbox;
  }
}

.checkbox-animation {
  height: $checkbox-dimensions;
  width: $checkbox-dimensions;
  background-color: $background-color;
  border: 1px solid $unchecked-color;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 5px;
  z-index: get-zindex('base');
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  transition: border-color ease $duration*0.5;
  @include margin(right);

  &::before,
  &::after {
    box-sizing: border-box;
    position: absolute;
    height: 0;
    width: $checkbox-dimensions * 0.2;
    display: inline-block;
    transform-origin: left top;
    border-radius: 5px;
    content: ' ';
    transition: opacity ease 0.5s;
  }

  &::before {
    top: $checkbox-dimensions * 0.72;
    left: $checkbox-dimensions * 0.41;
    opacity: 0;
    box-shadow: 0 0 0 $checkbox-dimensions * 0.02 rgba(0, 0, 0, 0.1);
    transform: rotate(-135deg);
  }

  &::after {
    top: $checkbox-dimensions * 0.37;
    left: $checkbox-dimensions * 0.05;
    transform: rotate(-45deg);
  }

  &--indeterminate {
    @extend .checkbox-animation;

    &::before {
      box-sizing: border-box;
      position: absolute;
      height: 4px;
      width: 80%;
      background-color: $checked-color;
      display: inline-block;
      transform-origin: left top;
      border-radius: 5px;
      content: ' ';
      transition: opacity ease 0.5s;
      top: 41%;
      left: 1px;
      opacity: 1;
      box-shadow: 0 0 0 $checkbox-dimensions * 0.02 rgba(0, 0, 0, 0.1);
      transform: rotate(0);
    }
  }
}

input[type='checkbox']:checked + .checkbox-animation,
.checkbox-animation.checked
 {
  border-color: $checked-color;

  &::after {
    background-color: $checked-color;
    height: $checkbox-dimensions * 0.5;
    animation: dothabottomcheck $duration*0.5 ease 0s forwards;
  }

  &::before {
    opacity: 1;
    background-color: $checked-color;
    height: $checkbox-dimensions * 1.1;
    animation: dothatopcheck $duration ease 0s forwards;
  }
}

input[type='checkbox']:disabled + .checkbox-animation {
  background-color: $input-background-color-disabled;
  border-color: $input-border-color-disabled;

  &::after {
    background-color: $disabled-color;
  }

  &::before {
    opacity: 1;
    background-color: $disabled-color;
  }
}

/* Checkmark style starts */
@keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: $checkbox-dimensions*0.5;
  }
}

@keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: $checkbox-dimensions * 1.1;
  }
}
