
$progress-list-width: 350px;
$progress-bar-min-width: 298px;

@keyframes progress {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: $progress-bar-min-width;
  }
 }

.progress-lists {
  position: absolute;
  bottom: 0;
  right: 50px;
  z-index: get-zindex('progress-lists');
  @include flex(row, flex-end, flex-start);
 }

.progress-list {
  width: $progress-list-width;
  background-color: get-colour('white');
  border: 1px solid get-colour('grey-lighter-60');
  z-index: get-zindex('progress-list');
  @include flex(column, center, flex-start);
  @include margin(left);

  &__title {
    @extend .p3;
    width: 100%;
    max-width: $progress-list-width - 110;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__task-name {
    @extend .progress-list__title;
    color: get-colour('secondary-text');
  }

  &__task-description {
    @extend .progress-list__title;
    white-space: break-spaces;
    word-wrap: anywhere;
    @include margin(top, spacing-02);
  }

  &__task-size {
    @extend .p3;
    width: 100%;
    color: get-colour('secondary-text');
    @include flex(row, flex-start, center);
    @include padding(left);
  }

  &__container {
    width: 100%;
    height: 500px;
    overflow-y: auto;
    @include padding(horizontal);
    @include flex(column, flex-end, flex-start);
  }

  &__header {
    width: 100%;
    max-width: $progress-list-width;
    height: 30px;
    background-color: get-colour('grey-lighter-80');
    @include padding(horizontal);
    @include flex(row, center, space-between);

    &-icons {
      width: 100%;
      @include flex(row, center, flex-end);
    }

    & svg {
      fill: get-colour('grey-lighter-20');
    }
  }

  &__item {
    position: relative;
    width: 100%;
    border: 1px solid get-colour('grey-lighter-80');
    border-radius: 4px;
    @include margin(top);
    @include padding(all, spacing-02);

    &-info {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr .5fr .2fr;
      @include margin(bottom, spacing-04);
    }

    &-progress {
      @include flex(column, flex-end, flex-end);
      height: 22px;

      &-percentage {
        @extend .overline;
        font-weight: 400;
        color: get-colour('secondary-text');
      }
    }

    &-progress-bar {
      &-container {
        width: 100%;
        height: 6px;
        @include flex(row, center, flex-start);
        background-color: get-colour('grey-lighter-80');
        background-clip: content-box;
        border-radius: 4px;
      }

      height: 100%;
      border-radius: 4px;

      &--succeeded {
        @extend .progress-list__item-progress-bar;
        background-color: get-colour('success')
      }

      &--failed {
        @extend .progress-list__item-progress-bar;
        background-color: get-colour('error')
      }

      &--in-progress {
        @extend .progress-list__item-progress-bar;
        background-image: repeating-linear-gradient(
          45deg,
          get-colour('green-lighter-40'),
          get-colour('green-lighter-40') 8.3%,
          get-colour('green-lighter-20') 16.6%,
          get-colour('green-lighter-20') 24.9%,
          get-colour('green-lighter-40') 33.2%,
          get-colour('green-lighter-40') 41.3%,
          get-colour('green-lighter-20') 49.8%,
          get-colour('green-lighter-20') 58.1%,
          get-colour('green-lighter-40') 66.4%,
          get-colour('green-lighter-40') 74.7%,
          get-colour('green-lighter-20') 83%,
          get-colour('green-lighter-20') 91.3%,
          get-colour('green-lighter-40') 100%
        );
        animation: progress 2.5s linear infinite;
        will-change: background-position;
        transition: width 0.1s ease;
      }
    }

    &-status {
      display: grid;
      grid-template-columns: 1fr 1fr;
      &-icon {
        & svg {
          color: get-colour('grey-lighter-60');
        }

        &--succeeded {
          & svg {
            fill: get-colour('success');
          }
        }

        &--in-progress {
          & svg {
            fill: get-colour('green-lighter-20');
          }
        }

        &--failed {
          @include flex(row, center, flex-start );

          & > * {
            @include margin(right);
          }

          & svg {
            fill: get-colour('error');
          }
        }
      }
    }

    &-close-button {
      position: absolute;
      right: 0;
      top: 0;

      & svg {
        fill: get-colour('grey-lighter-40');
      }
    }
  }

  &__counter {
    @extend .p3;
    @include margin(horizontal);
  }
}
