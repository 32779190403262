.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.flex-row {
  @include flex(row, center, flex-start);
}

.flex-column {
  @include flex(column, flex-start, center);
}

.no-margin {
  @include margin(all, none, true);
}

.font-weight-lighter {
  font-weight: $font-weight-lighter !important;
}

.font-weight-light {
  font-weight: $font-weight-light !important;
}

.font-weight-normal {
  font-weight: $font-weight-normal !important;
}

.font-weight-bold {
  font-weight: $font-weight-bold !important;
}

.font-weight-bolder {
  font-weight: $font-weight-bolder !important;
}

.cursor-pointer {
  cursor: pointer;

  &[disabled] {
    cursor: default;
  }
}

.overflow-y-auto {
  overflow-y: auto;
}

.right {
  float: right;
}

.center {
  margin: 0 auto;
}

.warning {
  color: get-colour('warning');
}

.form-warning {
  border-color: get-colour('warning') !important;
}

.error {
  color: get-colour('error');
}

.form-error {
  border-color: get-colour('error') !important;
}