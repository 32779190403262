.link-component {
  @include flex(column, flex-start, flex-start);

  &__link {
    @extend .link;
  }

  & > * {
    @include margin(bottom, spacing-02);
  }
}