.fieldset {
  @include flex(column, flex-start, space-between);
  border-color: get-colour('grey-lighter-80');

  &__container {
    width: 100%
  }

  &__header {
    @extend .input__label;

    &-container {
      @include flex(row, center, flex-start);

      & small {
        @include margin(bottom);
      }
    }
  }

  &--inline {
    @extend .fieldset;
    @include flex(row, center, flex-start);

    & > * {
      @include margin(right);
    }
  }
  &--split {
    @extend .fieldset;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    & > div {
      @include padding(horizontal, spacing-02);
      @include padding(vertical, spacing-02);
    }
  }
  &--span {
    @extend .fieldset;
    grid-column: 1 / -1;
  }

  & > * {
    @include margin(bottom, spacing-03);
  }
}
