.update-transactions-modal {
  @include flex(column, flex-start, flex-start);

  & > * {
    @include margin(bottom, spacing-03);
  }

  &__checkboxes {
    @include margin(top, spacing-02);

    & > * {
      @include margin(bottom, spacing-02);
    }
  }
}