$info-colour: get-colour('blue');
$info-is-read-colour: get-colour('blue-lighter-60');

$high-attention-colour: get-colour('red');
$high-attention-is-read-colour: get-colour('red-lighter-60');

$success-colour: get-colour('lime');
$success-is-read-colour: get-colour('lime-lighter-60');

.notification-list {
  height: 100vh;
  width: 300px;
  background-color: get-colour('white');
  position: fixed;
  right: 0;
  top: 0;
  z-index: get-zindex('notification-list');
  transition: right $transition;
  overflow-y: auto;

  &--hidden {
    @extend .notification-list;
    right: -300px;
  }
}

.notification {
  position: relative;
  width: 100%;
  height: 12.13rem;
  background-color: get-colour('grey-lighter-90');
  border-bottom: 1px solid rgba(get-colour('grey-lighter-60'), 0.3);
  cursor: pointer;
  @include flex(column, flex-start, flex-start);

  &--is-read {
    @extend .notification;
    cursor: default;
    background-color: get-colour('white');
  }
}

.notification__content {
  height: 85%;
  width: 100%;
  @include flex(row, center, flex-start);
}

.notification__icon {
  @include margin(left);

  & svg {
    width: 2rem;
    height: 2rem;
  }

  &--info {
    @extend .notification__icon;
    & svg {
      color: $info-colour;
    }

    &--is-read {
      @extend .notification__icon;

      & svg {
        color: $info-is-read-colour;
      }
    }
  }

  &--high-attention {
    @extend .notification__icon;
    & svg {
      color: $high-attention-colour;
    }

    &--is-read {
      @extend .notification__icon;

      & svg {
        color: $high-attention-is-read-colour;
      }
    }
  }

  &--success {
    @extend .notification__icon;
    & svg {
      color: $success-colour;
    }

    &--is-read {
      @extend .notification__icon;

      & svg {
        color: $success-is-read-colour;
      }
    }
  }
}

.notification__read-more {
  width: 77%;
  height: 2rem;
  position: relative;

  &-btn {
    position: absolute;
    left: 0px;
    bottom: 0px;
    button {
      padding: unset !important;
      .button-text {
        font-weight: unset !important;
      }
    }
  }
}

.notification__title {
  @extend .h4;
  height: 1.5rem;
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification__body {
  $notification-text-lines: 3;
  $notification-left-border: 4px solid;
  width: 100%;
  max-width: 230px;
  height: calc(#{$p2-line-height} * #{$notification-text-lines} + 5.7rem);
  @include margin(bottom);
  @include margin(left, spacing-01);
  @include padding(left, spacing-01);

  &--info {
    @extend .notification__body;
    border-left: $notification-left-border get-colour('blue');
  }

  &--success {
    @extend .notification__body;
    border-left: $notification-left-border get-colour('lime');
  }

  &--high-attention {
    @extend .notification__body;
    border-left: $notification-left-border get-colour('red');
  }

  &-text {
    @extend .p2;
    display: -webkit-box;
    -webkit-line-clamp: $notification-text-lines;
    -webkit-box-orient: vertical;
    width: 95%;
    height: calc(#{$p2-line-height} * #{$notification-text-lines});
    overflow: hidden;
  }
}

.notification__notify-time {
  @extend .p3;
  color: get-colour('grey-lighter-40');
  @include margin(top, spacing-02);
}

.notification__action-btns {
  width: 100%;
  position: absolute;
  height: 2.1rem;
  bottom: 0;
  left: 0;
  background-color: rgba(get-colour('grey-lighter-90'), 0.3);
  @include flex(row, center, flex-end);

  &--info {
    @extend .notification__action-btns;
    border-top: 1px solid rgba(get-colour('blue'), 0.1);
  }

  &--high-attention {
    @extend .notification__action-btns;
    border-top: 1px solid rgba(get-colour('red'), 0.1);
  }

  &--success {
    @extend .notification__action-btns;
    border-top: 1px solid rgba(get-colour('lime'), 0.1);
  }
  button {
    padding: unset !important;
    .button-text {
      font-weight: unset !important;
    }
  }
}

.notification__empty-state {
  @include padding(all, spacing-02);
  @include flex(column, center, center);
  @include margin(top, spacing-04);

  &-icon {
    height: 6rem;
    width: 6rem;
    border-radius: 50px;
    background-color: get-colour('grey-lighter-90');
    @include flex();

    & svg {
      width: 3rem;
      height: 3rem;
      fill: get-colour('grey-lighter-20');
    }
  }

  &-heading {
    @extend .h4;
    color: get-colour('grey-lighter-20');
    @include margin(top, spacing-04);
  }
}

.notification__modal {
  width: 31.25rem;
  border-radius: 2px;
  @include flex(column, flex-start, flex-start);
  @include padding(all, none);

  &-title {
    @extend .h4;
    position: relative;
    width: 100%;
    height: 2rem;

    @include flex(row, center, flex-start);
    @include padding(horizontal);

    & svg {
      height: 1.25rem;
      width: 1.25rem;
      @include margin(right);
    }

    &--info {
      @extend .notification__modal-title;
      background-color: get-colour('blue-lighter-90');
      color: get-colour('blue-darker-40');
      & svg {
        color: get-colour('blue-darker-40');
      }
    }

    &--high-attention {
      @extend .notification__modal-title;
      background-color: get-colour('red-lighter-90');
      color: get-colour('red-darker-40');
      & svg {
        color: get-colour('red-darker-40');
      }
    }

    &--success {
      @extend .notification__modal-title;
      background-color: get-colour('lime-lighter-90');
      color: get-colour('lime-darker-40');
      & svg {
        color: get-colour('lime-darker-40');
      }
    }
  }

  &-close-button {
    position: absolute;
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    right: 5px;
    top: 0;
  }

  &-body {
    @extend .p2;
    @include padding(all, spacing-02);
  }

  &-notify-time {
    @extend .p3;
    width: 100%;
    color: get-colour('grey-lighter-40');
    @include flex(row, center, flex-end);
    @include padding();
  }
}
