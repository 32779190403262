.App {
  @include flex(row, flex-start, flex-start);
  max-width: 100vw;
  background-color: get-colour('grey-lighter-90');
}

.App__main-area {
  height: 100vh;
  transition: all 0.4s ease-in-out;
  overflow-x: auto;
  overflow-y: hidden;
  @include flex(column, flex-start, flex-start);

    @include for-screen-size(phone, up) {
      max-width: $main-area-width-phone;
      min-width: $main-area-width-phone;
    }

    @include for-screen-size(tablet, up) {
      max-width: $main-area-width-tablet;
      min-width: $main-area-width-tablet;
    }

    @include for-screen-size(desktop, up) {
      max-width: $main-area-width-desktop;
      min-width: $main-area-width-desktop;
    }

    &--collapsed {
      @extend .App__main-area;
      @include for-screen-size(phone, up) {
        max-width: $main-area-width-phone-collapsed;
        min-width: $main-area-width-phone-collapsed;
      }

      @include for-screen-size(tablet, up) {
        max-width: $main-area-width-tablet-collapsed;
        min-width: $main-area-width-tablet-collapsed;
      }

      @include for-screen-size(desktop, up) {
        max-width: $main-area-width-desktop-collapsed;
        min-width: $main-area-width-desktop-collapsed;
      }
    }

    &--full-width {
      @extend .App__main-area;
      min-width: 100%;
      max-width: 100%;
    }
}

.App__outer-container {
  width: 100%;
  min-height: calc(100vh - #{$topbar-height});
}

.App__inner-container {
  flex-wrap: wrap;
  @include flex(row, flex-start, flex-start);
  @include padding(horizontal, spacing-03);
  @include padding(top, spacing-08);
  @include padding(bottom, spacing-02);

  &--no-padding {
    @extend .App__inner-container;
    padding: 0;
  }
}

.react-select {
  position: absolute;
  z-index: get-zindex('react-select');
}

