.cost-item {
  &__row {
    display: grid;
  grid-template-columns: 1fr 1fr 2fr 0.5fr;
  gap: 4px;
  grid-auto-rows: minmax(1rem, auto);
  align-items: center;
  }

  &__remove-icon{
    color: get-colour('error');
    height: 100%;
    @include flex(column, flex-start, center);
    @include padding(top, spacing-06);
    @include margin(left);

    & svg {

      cursor: pointer;
      &:hover{
        color: scale-color(get-colour('error'), $lightness: -20%);
      }
    }
    }

}
