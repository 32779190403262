// ----------------------------------------------------------------------
// Common
// classes shared between components and pages
// ----------------------------------------------------------------------

$overlay-transition: 0.2s;
$z-index-delay: 0.3s;

.overlay {
  @extend .min-vw-100;
  @extend .min-vh-100;
  position: absolute;
  opacity: 1;
  left: 0;
  top: 0;
  z-index: get-zindex('modal-overlay-show');
  transition: opacity $overlay-transition ease-in-out, z-index 0s ease-in-out;
  background-color: rgba(0, 0, 0, 0.4);

  &--hidden {
    @extend .overlay;
    opacity: 0;
    z-index: get-zindex('modal-overlay-hidden');
    transition: opacity $overlay-transition ease-in-out,
      z-index 0s ease-in-out $z-index-delay;
  }
}

.link {
  text-decoration: none;
  color: get-colour('blue');
  cursor: pointer;

  &:hover {
    color: get-colour('blue-darker-20');
  }
}

.change-location-modal {
  &__content {
    width: 100%;
  }
}

.bulleted-list {
  li {
    list-style-type: circle;
    @include margin(left, spacing-03);
  }
}