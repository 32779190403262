.clone-rough-stones-modal {
  width: min-content;
  @include flex(row, flex-start, flex-start);

  &__rough-list {
    width: 100%;
    min-width: 450px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    @include flex(column, flex-start, flex-start);
    @include margin(right, spacing-04);

    &-row {
      width: 100%;
      border-bottom: 1px solid get-colour('grey-lighter-80');
      display: grid;
      grid-template-columns: 1fr 1fr;
      @include margin(bottom);
      @include padding(all);

      & > * {
        @include margin(right);
      }

      & span {
        overflow-wrap: anywhere;
      }
    }

    &-header {
      @extend .clone-rough-stones-modal__rough-list-row;
      background-color: get-colour('grey-lighter-90');
      position: sticky;
      top: 0;
    }
  }

  &__assortment-list {
    width: 100%;
    min-width: 450px;
    border-left: 1px solid get-colour('grey-lighter-80');
    @include flex(column, flex-end, flex-start);
    @include padding(left, spacing-04);

    &-row {
      @include flex(row, center, flex-start);
    }

    & > * {
      @include margin(bottom);
    }
  }
}
