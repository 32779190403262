.modal {
  max-height: 98vh;
  max-width: 100vw;
  overflow-y: auto;
  background-color: get-colour('grey-lighter-90');

  &__content {
    @include padding(bottom, spacing-07);
    @include padding(horizontal, spacing-04);
  }
}

.modal__header {
  width: 100%;
  text-transform: capitalize;
  background-color: get-colour('grey-lighter-80');
  @include flex(row, center, space-between);
  @include padding(all, spacing-02);
  @include margin(bottom, spacing-05);
}

.modal__title {
  @include padding(right);
}

.modal__close-icon {
  cursor: pointer;
  @include margin(left);
  @include flex(row, center, center);

  & svg {
    color: get-colour('grey');
  }
}

.confirm-dialog__modal-buttons, .modal__buttons {
  @include flex(row, center, flex-end);
  @include margin(top, spacing-10);

  & > * {
    @include margin(left);
  }
}

.confirm-dialog__line, .modal__message {
  width: 100%;
  @include margin(vertical, spacing-04);
}

.upload-prices-modal__container {
  max-width: 50vw;
}

.submit-order-confirmation-modal,
.action-confirmation-modal {
  &__input {
    &-container {
      max-width: 50vw;
      display: flex;
      .checkbox__container {
        width: 1.5rem;
      }
    }
  }
  &__message {
    max-width: 50vw;
    @include margin(bottom, spacing-06);
    @include margin(left, spacing-03);
    line-height: 25px;
  }

  &__download-file {
    &-container {
      width: 492px;
      height: 369px;
      @include flex(column, center, center);
    }

    &-title {
      width: 100%;
      height: 40px;
      background-color: get-colour('grey-lighter-90');
      @include padding();
      @include flex(row, center, space-between);
    }

    &-content {
      width: 100%;
      height: 100%;
      @include flex(column, center, center);
      @include margin(bottom, spacing-04);
    }

    &-heading {
      @extend .h4;
      @include margin(vertical, spacing-06);
    }
  }
  &__form {
    &-container {
      max-width: 50vw;
      .form {
        &__container{
          background-color: unset;
          border-radius: unset;
          border: unset;
          padding: unset;
        }
        &__title{
          display: none;
        }
      }
    }
  }
}

.x-scroll {
  &-content{
    > .modal {
      overflow-x: auto;
    }
  }
}

.sticky {
  &-content{
    .modal__header {
      position: sticky;
      top: 0;
      left: 0;
      z-index: get-zindex('modal-sticky');
    }
  }
}

.planning-details-modal-content > .modal > .modal__content {
  width: 600px;
}